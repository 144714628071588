<template>
	<div class="pagenotfound">
		<div
			class="min-w-screen bg-gray-800 bg-opacity-75 flex flex-col gap-9 px-5 py-10"
		>
			<div class="flex justify-center">
				<div class="flex flex-col items-center">
					<h1 class="font-bold text-red-500 text-7xl">404</h1>
					<h6
						class="mb-2 text-2xl font-bold text-center text-gray-100 md:text-3xl"
					>
						<span class="text-red-700">{{ lang.oops }}</span>
						{{ lang.pnf }}
					</h6>
					<p class="mb-8 text-center text-gray-400 md:text-lg">
						{{ lang.msg }}
					</p>
					<router-link
						to="/"
						class="px-6 py-2 text-sm font-semibold text-red-800 bg-red-100"
						>{{ lang.goback }}</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lang: {
					pnf: "Page not found",
					oops: "Oops!",
					msg: "The page you’re looking for doesn’t exist.",
					goback: "Go home",
				},
			};
		},
		mounted() {},
	};
</script>
